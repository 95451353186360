module.exports ={
  "propertyService": "https://s40a6g4bth.execute-api.us-east-1.amazonaws.com/hotfix1",
  "domusoApiService": "https://uffzf30dpc.execute-api.us-east-1.amazonaws.com/hotfix1",
  "appService": "https://domusograilsweb-hotfix1.devmuso.com",
  "reservationService": "https://j0oja4227h.execute-api.us-east-1.amazonaws.com/hotfix1",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://iq3v4735ai.execute-api.us-east-1.amazonaws.com/hotfix1",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-hotfix1.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-hotfix1.devmuso.com",
  "maintenanceUrl": "https://iq3v4735ai.execute-api.us-east-1.amazonaws.com/hotfix1/maintenance",
  "env": "hotfix1"
}